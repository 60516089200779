



















































import Vue from 'vue';
import {
  Notice,
  NoticeConnection,
  NoticeEdge,
  NoticeTypeInfo,
  SERVICE,
} from '@/domain/notice/model/Notice';
import { Table, TableColumn } from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { NOTICE_CATEGORY_LIST, NOTICES } from '@/domain/notice/queries/query';
import NoticeTabs from '@/domain/notice/components/NoticeTabs.vue';
import NoticeTableForm from '@/domain/notice/components/NoticeTableForm.vue';

export default Vue.extend({
  name: 'HostNoticeContainer',
  components: {
    NoticeTableForm,
    NoticeTabs,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
  },
  data(): {
    notices: Notice[];
    loading: boolean;
    tabIndex: number;
    filter: { forHostOnly: true; titleLike: '' };
    page: number;
    size: number;
    totalCount: number;
    categories: NoticeTypeInfo[];
    tabValue: string | undefined;
  } {
    return {
      notices: [],
      loading: true,
      tabIndex: 0,
      filter: {
        forHostOnly: true,
        titleLike: '',
      },
      page: 1,
      size: 20,
      totalCount: 0,
      categories: [],
      tabValue: undefined,
    };
  },
  watch: {
    $route(): void {
      this.$data.filter.forHostOnly =
        this.$route.params.noticeType == SERVICE.toLowerCase() ? false : true;
      delete this.$data.filter.type;
      //tab 초기화
      (this as any).moveTab(undefined);

      this.$apollo.queries.notices.refetch();
    },
  },
  created() {
    this.$data.filter.forHostOnly =
      this.$route.params.noticeType == SERVICE.toLowerCase() ? false : true;
  },
  methods: {
    getNoticeList(): void {
      this.$apollo.queries.notices.refetch();
    },
    moveTab(type: string | undefined) {
      if (type) {
        this.$data.filter.type = type;
      } else {
        delete this.$data.filter.type;
      }
      this.$data.tabValue = type;
      this.$data.page = 1;
      this.$apollo.queries.notices.refetch();
    },
    // detailNotice(): void {
    //   this.$router.push({
    //     name: 'HostNoticeDetail',
    //     params: {
    //       forHostOnly: this.$data.filter.forHostOnly.toString(),
    //     },
    //   });
    // },
  },
  apollo: {
    notices: {
      query: NOTICES,
      variables() {
        return {
          filter: this.$data.filter,
          page: Number(this.$data.page),
          size: Number(this.$data.size),
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{ board: { notices: NoticeConnection } }>
      ) {
        this.$data.totalCount = result.data.board.notices.totalCount;
        this.$data.notices = result.data.board.notices.edges.map(
          (row: NoticeEdge) => {
            return row.node;
          }
        );

        this.$data.loading = false;
      },
      update: data => {
        return data.board.notices;
      },
    },
    noticeTypes: {
      query: NOTICE_CATEGORY_LIST,
      variables() {
        return {
          param: { forHostOnly: this.$data.filter.forHostOnly },
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(result: ApolloQueryResult<{ board: { noticeTypes: any[] } }>) {
        this.$data.categories = result.data.board.noticeTypes;
      },
      update: data => {
        return data.board.noticeTypes;
      },
    },
  },
});
